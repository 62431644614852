import React from 'react'

const support = () => {
    return (
        <>
            <div class="clearfix"></div>

            <br />

            <section class="homepage-hero main_head data-news-grid=">
                <div class="inner-banner-header wf100">
                    <h1 data-generated="Privacy Policy">Support KiniScore</h1>
                    <p>When you support KiniScore with your subscription purchase, you help us grow!</p>
                    <p>Home / Support Us</p>
                </div>
            </section>
            <div class="clearfix"></div>
            <section>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12 mb-5">
                            <h3 class="text-center mt-5">How do we Support KiniScore?</h3>
                            <p>When we started, we knew it will be though as clubs and players data acquisition is hard work in any geography, but it's particularly difficult across Africa's growth markets, which endure pronounced information asymmetries.

The resulting data gap makes it even much more difficult for international stakeholders to rely on existing structures to trade and investors to buy equity across Africa, this is keeping many African markets less efficient and consequently, less competitive.  

At KiniScore, we powered 30+ clubs and over 3000 young players using technology to improve the process, from players, clubs to fans. This way, talented players can get necessary exposure and needed opportunities out of Africa. 

One step at a time!

With the new year, we will keep growing with your ideas, suggestions and support.
✅📈⚽📊</p>

                            <p>
                                More updates coming soon!
                            </p>

                            <div class="col-md-4 offset-md-4">


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default support;