import './style.css';
import Router from './routes/routes';
import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify'
import { ToasterContext } from './context/toasterContext'
// import { DataStorageContext } from './context/dataStorageContext'

function App() {
  return (
    <React.Fragment>
      <ToasterContext>
          <Router />
      </ToasterContext>
      <ToastContainer
        position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick
        rtl={false} pauseOnFocusLoss draggable theme='dark' pauseOnHover={false}
      />
    </React.Fragment>

  )
}

export default App;
