// ** React Imports
import { Suspense, useContext, lazy, Fragment } from 'react'
import { appRoutes } from './router/index'
import { BrowserRouter as AppRouter, Route, Routes } from 'react-router-dom'
import Header from '../componets/header'
import Footer from '../componets/footer'

export function App() {
  const routes = appRoutes;
  return (
    <Suspense fallback={''}>
      <AppRouter>
        <Header />
        <Routes>
          {routes.map(route => {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={< route.component />}
              />
            );
          })}
        </Routes>
        <Footer />
      </AppRouter>
    </Suspense>
  );
}

export default App;
