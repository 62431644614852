// TODO: server base url 
// const baseUrl = 'http://69.164.193.66:8000'
import dayjs from 'dayjs';
export const baseUrl = 'https://api.kiniscore.com'
// TODO: server end points  

export const apiEndPoints = {
    login: '/signin',
    signup: '/signup',
    getPlayerByPosition: '/all_players_position',
    getPlayerDetails: '/single_player_details',
    getClub: '/all_players_gender',
    getClubDetails: ''
}

export const AlertTypes = {
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR',
    INFO: 'ALERT_INFO',
    LOADING: 'ALERT_LOADING',
    UPDATE_SUCCESS: 'ALERT_UPDATE_SUCCESS',
    UPDATE_ERROR: 'ALERT_UPDATE_ERROR',
    UPDATE_INFO: 'ALERT_UPDATE_INFO'
}

export const AppMessages = {
    //////////////
    noEmptyFileds: 'Please select or fill all the fileds to contine',
    loginFirst: 'Please Login First '
}


export const customStyles = {
    headRow: {
        style: {
            border: 'none',
            borderBottom: '1px solid gray'
        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};


export const setUserToken = (token) => localStorage.setItem('tokken', JSON.stringify(token))
export const isUserLoggedIn = () => localStorage.getItem('tokken')
export const isKeyEntered = () => localStorage.getItem('s_key')
export const getUserData = () => JSON.parse(localStorage.getItem('tokken'))
export const getHeaderToken = () => {
    const token = getUserData()
    return `Bearer ${token}`
}


export const testWorkFlowsColumns = [
    {
        name: 'First Name',
        sortable: true,
        selector: row => row.first_name,
    },
    {
        name: 'Last Name',
        sortable: true,
        selector: row => row.last_name,

    },
    {
        name: 'Position',
        sortable: true,
        selector: row => row.position
    },
    {
        name: 'Team/Club',
        sortable: true,
        selector: row => row.club
    },
    {
        name: 'Share/Player',
        sortable: true,
        selector: row => row.squad
    }
]

export const getCurrentDate = (separator = '') => {
    let now = dayjs().add(-1, 'day');
    let pareseNow = now.toString().split(" ");
    let newDate  = pareseNow.splice(-2);
    return pareseNow.join(' ')
}
