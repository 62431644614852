import { lazy } from 'react'
import support from '../../pages/support';
const TemplateTitle = 'UmarAslam'
const Home = lazy(() => import('../../pages/home'));
const Contact = lazy(() => import('../../pages/contact'));
const Faq = lazy(() => import('../../pages/faq'));
const Market = lazy(() => import('../../pages/market'));
const Login = lazy(() => import('../../pages/login'));
const SignUp = lazy(() => import('../../pages/signup'));
const Club = lazy(() => import('../../pages/club'));
const Player = lazy(() => import('../../pages/player'));
const AgentSignUp = lazy(() => import('../../pages/agentSignUp'));
const PlayerDetail = lazy(() => import('../../pages/player/playerDetail'));
const ClubDetail = lazy(() => import('../../pages/club/clubDetail'));
const NotFound = lazy(() => import('../../pages/notFound'));
const PrivacyPolicy = lazy(() => import('../../pages/privacyPolicy'));
const Terms = lazy(() => import('../../pages/terms'));
const AboutUs = lazy(() => import('../../pages/aboutUs'));
const Support = lazy(() => import('../../pages/support'));

const appRoutes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/contact',
    component: Contact
  },
  {
    path: '/faq',
    component: Faq
  },
  {
    path: '/market-valuation',
    component: Market
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/signup',
    component: SignUp
  },
  {
    path: '/clubs/:type',
    component: Club
  },
  {
    path: '/players/:postion',
    component: Player
  },
  {
    path: '/agent-signup',
    component: AgentSignUp
  },
  {
    path: '/player/:name',
    component: PlayerDetail
  },
  {
    path: '/club/:name',
    component: ClubDetail
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy
  },
  {
    path: '/terms-and-conditions',
    component: Terms
  },
  {
    path:'/about-us',
    component:AboutUs
  },
  {
    path:'/support',
    component:Support
  },
  {
    path: '*',
    component: NotFound
  },
  

]

export { TemplateTitle, appRoutes }
