// ** React Imports
import { useEffect, useState, createContext } from 'react'
import { AlertTypes } from '../utils'
import { toast } from 'react-toastify'
// ** Create Context
const toasts = createContext()

const ToasterContext = ({ children }) => {
  // ** State
  const [didMount, setDidMount] = useState(false)
  const OPTIONS = {
    autoClose: true,
    closeButton: true,
    type: "success",
    isLoading: false,
    closeOnClick: true
  }

  useEffect(() => {
    setDidMount(true)
    return () => setDidMount(false)
  }, [])

  if (!didMount) {
    return null
  }

  const showToast = (info) => {

    if (info.type === AlertTypes.SUCCESS) {
      toast.success(info.message)
    } else if (info.type === AlertTypes.ERROR) {
      toast.error(info.message)
    } else if (info.type === AlertTypes.INFO) {
      toast.info(info.message)
    } else if (info.type === AlertTypes.LOADING) {
      return toast.loading(info.message)
    } else if (info.type === AlertTypes.UPDATE_SUCCESS) {
      OPTIONS['type'] = 'success'
      OPTIONS['render'] = info.message
      toast.update(info.id, OPTIONS)
    } else if (info.type === AlertTypes.UPDATE_ERROR) {
      OPTIONS['type'] = 'error'
      OPTIONS['render'] = info.message
      toast.update(info.id, OPTIONS)
    } else if (info.type === AlertTypes.UPDATE_INFO) {
      OPTIONS['type'] = 'info'
      OPTIONS['render'] = info.message
      toast.update(info.id, OPTIONS)
    }

  }

  return <toasts.Provider value={{
    ShowToast: showToast
  }} >{children}</toasts.Provider>
}

export { toasts, ToasterContext }