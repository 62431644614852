import React, { Component, useState } from 'react'
import { getCurrentDate } from '../../utils'
const Footer = () => {
    return (
        <section className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-2 col-6">
                        <h5>Who We Are</h5>
                        <ul>
                            <li><a href="/about-us">About Us</a></li>
                            {/* <li><a href="empty-template-page.html">News/Blog</a></li>
                            <li><a href="empty-template-page.html">Media Coverage</a></li>
                            <li><a href="empty-template-page.html">Join our Team</a></li>
                            <li><a href="empty-template-page.html">Partner KiniScore</a></li> */}
                            <li><a href="/clubs/male">Men Players</a></li>
                            <li><a href="/clubs/female">Women Players</a></li>
                        </ul>
                    </div>
                    <div className="col-md-2  col-6">
                        <h5>Attacking Players</h5>
                        <ul>
                            <li>
                                <a href="/players/Attacking-Midfield">Attacking-Midfield Players </a>
                            </li>
                            
                            <li>
                                <a href="/players/Center-Forward">Center-Forward Players </a>
                            </li>
                            <li>
                                <a href="/players/Central-Midfield">Central-Midfield Players</a>
                            </li>
        
                            <li>
                                <a href="players/Left-Winger">Left-Winger Players</a>
                            </li>
                            
                            <li>
                                <a href="/players/Right-Winger">Right-Winger Players</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-2  col-6">
                        <h5>Defensive Players</h5>
                        <ul>
                            <li>
                                <a href="/players/Center-Back">Center-Back Players</a>
                            </li>
                            
                            <li>
                                <a href="/players/Defensive-Midfield">Defensive-Midfield Players</a>
                            </li>
                            <li>
                                <a href="/players/Goalkeeper">Goalkeeper Players</a>
                            </li>
                            <li>
                                <a href="/players/Left-Back">Left-Back Players</a>
                            </li>
                            
                            <li>
                                <a href="/players/Rght-Back">Right-Back Players</a>
                            </li>
                            
                        </ul>
                    </div>
                    {/* <div className="col-md-2  col-6">
                        <h5>Merchandise <i className="comingsoon">Coming Soon!</i></h5>
                        <ul>
                            <li><a href="#">Jerseys</a></li>
                            <li><a href="#">Hoodies</a></li>
                            <li><a href="#">Socks/Boots</a></li>
                            <li><a href="#">Others</a></li>
                        </ul>
                    </div> */}
                    <div className="col-md-2  col-6">
                        <h5>Connect Us</h5>
                        <ul>
                            <li><a href="https://twitter.com/KiniScore">Twitter</a></li>
                            <li><a href="https://www.facebook.com/KiniScore/">Facebook</a></li>
                            <li><a href="https://www.instagram.com/KiniScore">Instagram</a></li>
                            <li><a href="#">LinkedIn</a></li>
                        </ul>
                    </div>
                    <div className="col-md-2  col-6">
                        <h5>Policies</h5>
                        <ul>
                            <li><a href="/privacy-policy">Privacy Policy</a></li>
                            <li><a href="/terms-and-conditions">Terms & Conditions</a></li>
                            {/* <li><a href="./legal/mordern-slavery-commitment.html">Mordern Slavery Commitment</a></li> */}
                        </ul>
                    </div>
                    <div className="col-md-2  col-6">
                        <h5>Support</h5>
                        <ul>
                            <li><a href="/faq">FAQs</a></li>
                            <li><a href="/support">Support KiniScore</a></li>
                        </ul>
                    </div>
                </div>
                <div>
                    <p className="text-center mt-5">Last Updated: {getCurrentDate('-')}</p>
                    <p className="text-center mt-5">KiniScore ©  2022. All Rights Reserved</p>
                </div>
            </div>
        </section>
    )
}

export default Footer;