import React, { Component } from 'react'
import logo from '../../images/logo-kiniscore.jpg'
import { useNavigate } from "react-router-dom";
const Header = () => {
    const navigate = useNavigate();
    const handleChange = (val) => {
        navigate('/login', { state: { path: val } });
    }

    return (
        <header className='site-header navigation-visible'>
            <div className="part second" id="myHeader">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2">
                            <div className="logo">
                                <a href="/">   <img src={logo} /></a>
                            </div>
                        </div>
                        <div className="col-md-6 col-3">
                            <nav className="navbar navbar-expand-lg navbar-light">
                                <div>
                                    <button className="navbar-toggler border-0 menu_mobile" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                            <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Clubs/Teams
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <li>
                                                        <a className="dropdown-item" href="/clubs/male" title="Mens Clubs and Teams">Men Clubs/Teams </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="/clubs/female" title="Women Clubs and Teams">Women Clubs/Teams</a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Playing Positions
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <li>
                                                        <a className="dropdown-item" href="/players/Attacking-Midfield">Attacking-Midfield Players </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="/players/Center-Back">Center-Back Players</a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="/players/Center-Forward">Center-Forward Players </a>c
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="/players/Central-Midfield">Central-Midfield Players</a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="/players/Defensive-Midfield">Defensive-Midfield Players</a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="/players/Goalkeeper">Goalkeeper Players</a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="/players/Left-Back">Left-Back Players</a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="players/Left-Winger">Left-Winger Players</a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="/players/Right-Back">Right-Back Players</a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="/players/Right-Winger">Right-Winger Players</a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="/market-valuation">Market Valuations </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="/faq">FAQ </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="/contact">Contact Us </a>
                                            </li>
                                            <li className="dis nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="fa fa-user-circle-o" aria-hidden="true"></i> Login/Register
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <li>
                                                        <a onClick={() => handleChange('isTeam')} href="">Teams/Club</a>
                                                    </li>
                                                    <li>
                                                        <a onClick={() => handleChange('isAgent')} href="">Agents/Scouts</a>
                                                    </li>
                                                    <li>
                                                        <a onClick={() => handleChange('isMedia')} href="">Journalists/Media</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                        <div className="col-md-4 col-9 login_sec">
                            {/* <div className="search"> <i className="fa fa-search"></i> <input type="text" className="form-control" placeholder="Search"/>   </div> */}
                            <div className="header">
                                <div className="dropdown">
                                    <button className="dropbtn mid" data-original-title="Login/Register" aria-describedby="tooltip890512">  <i className="fa fa-user-circle-o" aria-hidden="true"></i> Login/Register</button>
                                    <div className="dropdown-content">
                                        <a onClick={() => handleChange('isTeam')} href="">Teams/Club</a>
                                        <a onClick={() => handleChange('isAgent')} href="">Agents/Scouts</a>
                                        <a onClick={() => handleChange('isMedia')} href="">Journalists/Media</a>
                                    </div>
                                </div>
                                {/* <div className="dropdown">
                                    <button className="dropbtn"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-translate" viewBox="0 0 16 16">
                                        <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z" />
                                        <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z" />
                                    </svg> Language</button>
                                    <div className="dropdown-content">
                                        <a href="#">普通话</a>
                                        <a href="#">Français</a>
                                        <a href="#"> عربي </a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;